import { useNotify } from "react-admin";

export const useHandleErrorResponse = () => {
  const notify = useNotify();

  return (error: unknown) => {
    const exc = error as Error;

    if (exc.message) {
      notify(exc.message, {
        type: "error",
        autoHideDuration: 10000
      });

      return;
    }

    const errorObj = error as { body?: { error?: string } } | undefined;
    const errorMessage = errorObj?.body?.error;

    notify(typeof errorMessage === "string" ? errorMessage : "ra.notification.http_error", {
      type: "error",
      autoHideDuration: 10000
    });
  };
};
