export const colors = {
  black: "#000000",
  blackTransparent: "rgba(0,0,0,0.60)",
  blackText: "#000000DE",
  greyDark: "#767676",
  greyLunaDemo: "#acb3c1",
  greyLuna: "#c7c8c8",
  greyLight: "#f9f9f9",
  white: "#fff",
  greenLogo: "#008b40",
  greenLight: "#dae8da",
  greenLightContrast: "#2E7D32",
  activeGreen: "#44b700",
  green: "#43aabb",
  purple: "#9C27B0",
  purpleLight: "#9C27B01F",
  violet: "#8289DD",
  violetLight: "#8289DD1F",
  blueLight: "rgba(25, 118, 210, 0.08)",
  blueDarker: "#1976D2",
  blueGoogle: "#4285f4",
  redDark: "#970000",
  red: "#ea526f",
  redBackground: "rgba(253, 237, 237, 1)",
  redLight: "#D32F2F",
  redLightContrast: "#D32F2F1F",
  redBackgroundText: "rgb(95, 33, 32)",
  orange: "#ED6C02",
  orangeLight: "#ED6C021F",
  yellow: "#FDC02F",
  yellowLight: "#fdf3da"
};

export const purplePalette = { color: colors.purple, backgroundColor: colors.purpleLight };
export const violetPalette = { color: colors.violet, backgroundColor: colors.violetLight };
export const bluePalette = { color: colors.blueGoogle, backgroundColor: colors.blueLight };
export const orangePalette = { color: colors.orange, backgroundColor: colors.orangeLight };
